@use 'sass:math';
@import 'helpers';

* {
  outline: none !important;
}

a:focus,
input:focus,
button:focus,
div.card-img-left:focus,
.product-card-item:focus,
.cross-sell-card:focus,
.product-group-card-heading:focus,
button.btn-primary:focus,
button.btn-secondary:focus,
div.noUi-handle:focus {
  outline: 1px solid $green !important;
}

button.slider-modal-control:focus {
  outline: 1px solid $green !important;
}

span.ngx-slider-span:focus {
  outline: 1px solid white !important;
  border-radius: 0;
  transform: scale(1.15);
}

div.noUi-handle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 105, 56, 0.25);
  transform: scale(1.15);
}

//div.slick-slide:focus .product-variant-card-addon label {
//  border: 2px solid $green;
//}

html {
  &:not(.ws-view-categories-wrapper) {
    @include media-breakpoint-down(sm) {
      font-size: math.div(12, 16) * 1rem;
    }
  }
}

.app-container {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  position: relative;
  top: $nav-height-mobile;
  @include media-breakpoint-up(xl) {
    top: $nav-height-desktop;
  }

  &.digital-menu {
    top: 0;
  }

  &.app-banner {
    top: calc(#{$app-banner-height-mobile} + #{$nav-height-mobile});
  }
}

#body {
  padding-bottom: 451px;
}

.constrain {
  max-width: $max-width-desktop;
  margin-left: auto;
  margin-right: auto;
}

.page-header {
  padding: ws-get-spacing('medium') 0;
  background-color: ws-get-color('white');
  box-shadow: $box-shadow;
  position: relative;
  z-index: 1;
}

.page-header-content {
  @include content-container;
  display: flex;
  align-items: center;
}

.page-header-title {
  margin: 0 ws-get-spacing('medium') 0 0;
  flex: 1;
  font-size: math.div(28, 16) * 1rem;
}

.page-header-meta {
  text-align: right;
  margin-right: ws-get-spacing('medium');
}

.section-content {
  @include content-container;
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.btn {
  font-style: oblique;
  text-transform: uppercase;
}

.subheading {
  text-transform: none;
  font-size: $font-size-base;
}

.subheading-underline {
  font-weight: 700;
  font-style: normal;
  text-decoration: underline;
}

.btn {
  position: relative;
  font-family: $headings-font-family;
  padding-top: $input-btn-padding-y + 0.2;

  &.disabled,
  &:disabled {
    background-color: $black-50;
    border-color: $black-50;
  }

  &:hover,
  &:focus {
    .icon[type='arrow-long-right'] {
      transform: translateX(10px);
    }
  }
}

.btn-primary {
  &.disabled,
  &:disabled {
    background-color: $black-50;
    border-color: $black-50;
  }

  &:focus {
    box-shadow: none;

    &:after {
      top: -4px;
      left: -4px;
      content: '';
      position: absolute;
      border-radius: 5px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      background: transparent;
      border: 2px solid $green;
    }
  }
}

.btn-secondary {
  background-color: $green;
  border-color: $green;

  &:focus {
    box-shadow: none;

    &:after {
      top: -4px;
      left: -4px;
      content: '';
      position: absolute;
      border-radius: 5px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      background: transparent;
      border: 2px solid $black;
    }
  }
}

.btn-sm {
  padding-top: $input-btn-padding-y-sm + 0.2;
}

.btn-link {
  color: inherit;
  font-style: normal;
  text-transform: none;
  font-weight: 500;
  transition: none;
}

.btn .icon {
  margin-right: ws-get-spacing('xsmall');
}

.btn-group .btn + .btn {
  margin-left: 1px;
}

// Special case for arrow button
// Forces white fill on /nutrition button icon
.btn .icon[type='arrow-long-right'] {
  margin-right: 0;
  float: right;
  fill: white;
  transition: transform 0.4s ease;
}

.icon-btn {
  border: none;
  background: none;
  padding: 0;
}

.wing-calc-link {
  font-family: $font-family-base;
  font-style: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;

  span {
    font-size: 10px;
  }

  .icon[type='arrow-long-right'] {
    fill: $green;
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

.social-login-button {
  padding: 0;
  padding-bottom: 5%;
}

.close {
  opacity: 1;
  font-size: ws-get-font-size('small');
  text-shadow: none;
  font-weight: normal;
  margin-top: 2px;
  color: ws-get-color('black', 80);
}

// Skip link
#skip-link {
  position: fixed;
  left: calc(50% - 145px);
  z-index: 99999;
  opacity: 0;
  top: -100px;

  &:focus {
    top: 4px;
    opacity: 100;
  }
}

// Badges within buttons
.btn .badge {
  font-size: ws-get-font-size('normal');
  font-weight: 500;
  font-style: normal;
}

.action-button {
  width: 315px;
  height: 44px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
  }

  @include media-breakpoint-up(sm) {
    width: 375px;
    height: 60px;
  }

  @include media-breakpoint-down(md) {
    max-width: 100%;
  }

  svg {
    position: relative;
    left: 16px;
    bottom: 2px;
  }
}

.action-button-full-width {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
  }

  @include media-breakpoint-up(sm) {
    height: 60px;
  }

  svg {
    position: relative;
    left: 16px;
    bottom: 2px;
  }
}

// Cards
.card {
  box-shadow: $box-shadow;
  border: 0;
  overflow: hidden;
}

.card-img-left {
  display: flex;
  align-items: center;

  .card-img {
    position: relative;
    background: ws-get-color('black', 50) 50% 50% no-repeat;
    background-size: cover;
    width: 40%;
    align-self: stretch;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    flex-shrink: 0;
  }
}

.location-details {
  .restaurants {
    background: $white;
  }

  .detail-hours {
    padding: 60px 5%;
    background: $gold-50;

    .detail-text {
      margin-left: 10px;
    }

    a {
      color: $black;
    }

    .favorite-button {
      svg {
        fill: $green;
      }
    }

    .general-info {
      padding-right: 40px;

      .location-info {
        svg {
          fill: $green;
        }

        .detail-text {
          color: $green;
          margin-bottom: 0;
          font-weight: bold;
        }
      }

      .distance-text {
        margin-left: 35px;

        @include media-breakpoint-down(sm) {
          margin-left: 29px;
        }
      }
    }
  }

  .carryout-hours-list,
  .dispatch-hours-list {
    @include media-breakpoint-down(sm) {
      margin-top: 40px;
    }

    .location-hours {
      margin-bottom: 10px;

      &:first-child {
        margin-top: 20px;
      }

      .day,
      .hours {
        font-size: 14px;
      }
    }
  }

  .specials {
    margin: 60px 5%;

    .slick-dots {
      @include media-breakpoint-down(sm) {
        bottom: -70px;
      }
    }

    .carousel {
      padding: 0 !important;

      .hero-card {
        padding: 20px;

        @include media-breakpoint-down(sm) {
          padding: 0;
        }

        .heading {
          margin-top: 20px;
        }
      }

      .heading-name,
      .heading-description {
        height: 72px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .iconic-flavors {
    padding: 60px 5%;
    background: $gold-50;

    .carousel {
      background: transparent !important;
    }
  }

  .job-search {
    background: url('/assets/images/looking-job-image.jpg');
    background-size: cover;
    padding: 0 90px;

    @include media-breakpoint-down(sm) {
      padding: 40px;
      height: 400px;
    }

    button {
      width: 80%;
    }
  }

  .nearby-locations {
    padding: 60px 5%;
    background: $gray-100;

    .restaurants {
      padding: 0;
      background: transparent;
    }

    .card {
      height: auto !important;
    }
  }

  .about-location {
    padding: 60px 5%;
    background: $gray-100;
  }
}

.restaurants {
  .restaurants-card {
    margin-bottom: 30px;
  }

  .text-left {
    font-size: 0.9rem !important;
  }

  .card-body {
    display: flex;
    flex-direction: column;

    .navbar-delivery-actions {
      margin-top: auto;
    }
  }

  .card {
    margin: 0 0 30px !important;
    height: 100% !important;

    .btn-group {
      width: 100%;
    }
  }
}

.mobile-app-banner {
  padding-top: 15px;

  .banner-title {
    margin-top: 20px;
    padding-right: 20%;

    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }

  .logos-app {
    a {
      margin-right: 10px;

      &:focus {
        outline: 1px solid $green !important;
        outline-offset: 3px;
        border: none !important;
      }
    }
  }

  .banner-text {
    @include media-breakpoint-down(sm) {
      text-align: center;
      align-items: center;
      margin-bottom: 60px;
    }
  }

  .banner-image-left {
    margin-left: -15px;
  }
}

.social-media-links {
  height: 238px;
  color: $white;
  text-align: center;
  background: $green;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    padding: 0 40px;
  }

  .nav-item {
    a {
      color: $white;
    }
  }
}

.app-banner-logo {
  width: 230px;
}

.card-header + .card-img-left {
  .card-img {
    border-top-left-radius: 0;
  }
}

.card-footer {
  background-color: inherit;
}

.card-variant {
  cursor: pointer;
  border-radius: 0.25rem;
  background: transparent;
  border: 1px solid $black-50;

  &:focus {
    outline: none;
  }

  svg {
    fill: $green;
    opacity: 1 !important;
    margin-top: -4px;
    padding-right: 3px;

    @include media-breakpoint-down(sm) {
      margin-top: -2px;
      padding-right: 0;
    }
  }

  p {
    margin-bottom: 0;
  }

  .card-body {
    padding: 1.5rem 1.25rem 1.25rem;

    @include media-breakpoint-down(sm) {
      padding: 1.25rem 1rem 1rem;
    }
  }
}

.card-item {
  margin-top: 15px;

  .schedule {
    font-size: 13px;
  }

  &.phone {
    a {
      color: $black;
    }
  }
}

.card-address {
  height: 40px;

  @include media-breakpoint-down(md) {
    height: auto;
  }

  .address-text {
    overflow: hidden;
    max-height: 1.9em;
    position: relative;
    line-height: 1.9em;
    margin-right: -1em;
    padding-right: 1em;
    text-align: justify;

    &:before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1.5em;
      margin-top: 0.2em;
      background: white;
    }
  }
}

.info-icon,
.phone-icon {
  margin-right: 10px;
}

.wing-section-special-requests-button {
  margin: 0 3px;
}

// Tabs
.nav-tabs {
  border-bottom: 0;

  .nav-item {
    margin-bottom: 0;
  }

  .nav-link {
    border-color: ws-get-color('black', 50);
    background-color: ws-get-color('white');
    color: ws-get-color('black', 50);
    border-right-width: 0;
    padding: map-get($spacers, 3);
  }

  .nav-item:last-child {
    .nav-link {
      border-right-width: 1px;
    }
  }
}

.nav-tabs-gold {
  .nav-link {
    border-color: ws-get-color('gold', 'dark');
    background-color: ws-get-color('gold', 50);
    color: ws-get-color('black');

    @include hover-focus {
      border-color: ws-get-color('gold', 'dark');
    }
  }

  .nav-link.active,
  .nav-link.active:hover,
  .nav-item.show .nav-link {
    color: ws-get-color('black');
    background-color: ws-get-color('gold');
    border-color: ws-get-color('gold', 'dark');
  }

  /*
  .nav-link:active:hover {
    box-shadow: inset 0px 2px 4px rgba(0,0,0,0.3);
  }
  */

  .nav-item:first-of-type .nav-link {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .nav-item:last-of-type .nav-link {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

// Badges
.badge {
  height: math.div(32, 16) * 1rem;
  line-height: math.div(32, 16) * 1rem;
  display: inline-flex;
  align-items: center;

  .icon {
    font-size: ws-get-font-size('normal');
  }
}

// Forms
.custom-control-label {
  &::before {
    border: 2px solid ws-get-color('primary');
  }
}

// Custom Checkbox and Radio Button Styles - IE11 High Contrast Mode
.custom-radio,
.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label {
    // Default for All Browsers
    &::before {
      background-color: $green;
    }

    // IE11 High Contrast
    @media screen and (-ms-high-contrast: active) {
      &::before {
        background-color: $green;
      }
    }
    @media screen and (-ms-high-contrast: black-on-white) {
      &::before {
        background-color: black;
      }
    }
    @media screen and (-ms-high-contrast: white-on-black) {
      &::before {
        background-color: white;
      }
    }

    // Default for All Browsers
    &::after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !important;
    }

    // IE11 High Contrast
    @media screen and (-ms-high-contrast: active) {
      &::after {
        background-image: url('/assets/images/checkbox-white.png') !important;
      }
    }
    @media screen and (-ms-high-contrast: black-on-white) {
      &::after {
        background-image: url('/assets/images/checkbox-white.png') !important;
      }
    }
    @media screen and (-ms-high-contrast: white-on-black) {
      &::after {
        background-image: url('/assets/images/checkbox-black.png') !important;
      }
    }
  }
}

.custom-control-input {
  &:disabled {
    ~ .custom-control-label {
      &::before {
        border-color: rgba(ws-get-color('primary'), 0.6);
      }
    }
  }
}

.custom-radio-check {
  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: $custom-checkbox-indicator-icon-checked;
    }
  }
}

form {
  ::placeholder {
    color: $gray-4 !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $gray-4 !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $gray-4 !important;
  }
}

// Custom Range Variants
@each $color in $form-input-bg-colors {
  @include range-variant(
    '.custom-range-#{$color}',
    ws-get-color($color),
    ws-get-color($color, 80)
  );
}

// nouislider
.noUi-target {
  background-color: $custom-range-track-bg;
  border-radius: $custom-range-track-border-radius;
  box-shadow: $custom-range-track-box-shadow;
  border: 0;
  padding: 0 (math.div($custom-range-thumb-width, 2));
}

.noUi-gray-horizontal,
.noUi-green-horizontal,
.noUi-horizontal {
  height: $custom-range-track-height;
}

.noUi-horizontal {
  border-bottom: 4px solid ws-get-color('gold');
}

.noUi-green-horizontal {
  border-bottom: 4px solid ws-get-color('green');
}

.noUi-gray-horizontal {
  border-bottom: 4px solid ws-get-color('gray');
}

@media screen and (-ms-high-contrast: active) {
  .noUi-handle {
    border: 2px solid $green !important;
  }
}

@media screen and (-ms-high-contrast: black-on-white) {
  .noUi-handle {
    border: 2px solid black !important;
  }
}

@media screen and (-ms-high-contrast: white-on-black) {
  .noUi-handle {
    border: 2px solid white !important;
  }
}

.noUi-handle {
  @include transition($custom-forms-transition);
  @include border-radius($custom-range-thumb-border-radius);
  @include gradient-bg($custom-range-thumb-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  border: $custom-range-thumb-border;
  box-shadow: $custom-range-thumb-box-shadow;

  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-size 500ms, background-color 1000ms;

  &:focus {
    outline: none;
    box-shadow: $custom-range-thumb-focus-box-shadow;
  }

  &::before,
  &::after {
    content: none;
    display: none;
  }

  &:focus,
  &:hover {
    background-size: 40px 40px;
  }

  &:hover {
    cursor: pointer;
  }
}

.noUi-green-horizontal .noUi-handle,
.noUi-gray-horizontal .noUi-handle,
.noUi-horizontal .noUi-handle {
  width: $custom-range-thumb-width;
  height: $custom-range-thumb-height;
  left: -(math.div($custom-range-thumb-width, 2));
  top: (math.div(($custom-range-track-height - $custom-range-thumb-height), 2));
}

html:not([dir='rtl']) .noUi-green-horizontal .noUi-handle,
html:not([dir='rtl']) .noUi-gray-horizontal .noUi-handle,
html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
  right: -(math.div($custom-range-thumb-width, 2));
  left: auto;
}

html:not([dir='rtl']) .noUi-green-horizontal .noUi-origin,
html:not([dir='rtl']) .noUi-gray-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

.noUi-green-horizontal .noUi-origin,
.noUi-gray-horizontal .noUi-origin {
  height: 0;
}

.noUi-tooltip {
  border: 0;
  border-radius: $custom-range-thumb-border-radius;
  background: none;
  color: ws-get-color('white');
  width: 100%;
  text-align: center;
  padding: 0;
  font-family: $headings-font-family;
  font-weight: 900;
  font-style: oblique;
}

.noUi-green-horizontal .noUi-tooltip,
.noUi-gray-horizontal .noUi-tooltip,
.noUi-horizontal .noUi-tooltip {
  margin-top: 2px;
  margin-left: -1px;
  transform: none;
  bottom: auto;
  left: auto;
  right: auto;
  position: relative;
}

@each $color in $form-input-bg-colors {
  @include range-variant-complex(
    '.range-#{$color}',
    ws-get-color($color),
    ws-get-color($color, 80)
  );
}

.range-gold,
.range-white,
.range-red {
  .noUi-tooltip {
    color: ws-get-color('black');
  }
}

.range-white {
  .noUi-handle {
    color: ws-get-color('black');
    background-color: ws-get-color('white');
    border-color: ws-get-color('gold');
  }
}

@media screen and (-ms-high-contrast: active) {
  .fire-icon {
    .noUi-handle {
      &:before {
        content: url('/assets/icons/whats-hot-white.svg');
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
}

@media screen and (-ms-high-contrast: black-on-white) {
  .fire-icon {
    .noUi-handle {
      &:before {
        content: url('/assets/icons/whats-hot.svg');
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
}

@media screen and (-ms-high-contrast: white-on-black) {
  .fire-icon {
    .noUi-handle {
      &:before {
        content: url('/assets/icons/whats-hot-white.svg');
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
}

.fire-icon {
  .noUi-handle {
    background-image: url('/assets/icons/whats-hot.svg');
  }
}

.spice-intensity-0 .noUi-handle,
.svg-parent-green svg {
  background-color: $green;
  border-color: $green;
}

.spice-intensity-1 .noUi-handle,
.svg-parent-orange svg {
  background-color: $orange;
  border-color: $orange;
}

.spice-intensity-2 .noUi-handle,
.svg-parent-red svg {
  background-color: $red;
  border-color: $red;
}

/* Google auto complete */
.pac-item {
  height: 44px;
  display: flex;
  align-items: center;

  &,
  .pac-item-query {
    font-size: 16px;
  }
}

/* ng-select dropdown */
.product-summary-card {
  .ng-dropdown-panel {
    left: 0;
  }

  .ng-select.ng-select-single {
    .ng-select-container {
      height: 100%;
    }
  }

  .ng-select.ng-select-opened {
    .ng-select-container {
      z-index: 950;
    }
  }

  .ng-dropdown-panel {
    z-index: 999;
  }
}

// NG Select Drop Down Arrow
.ng-select {
  .ng-select-container {
    border-radius: 0;
  }

  // Arrow Active
  &.ng-select-opened > .ng-select-container {
    // Default Browser Styles
    .ng-arrow {
      border-color: transparent transparent $gray-3;
    }

    // IE11 High Contrast Styles
    @media screen and (-ms-high-contrast: active) {
      .ng-arrow {
        border-color: transparent transparent $green;
      }
    }
    @media screen and (-ms-high-contrast: black-on-white) {
      .ng-arrow {
        border-color: transparent transparent black;
      }
    }
    @media screen and (-ms-high-contrast: white-on-black) {
      .ng-arrow {
        border-color: transparent transparent white;
      }
    }
  }

  // Arrow Inactive
  .ng-arrow-wrapper {
    // Default Browser Styles
    .ng-arrow {
      border-color: $gray-3 transparent transparent;
    }

    // IE11 High Contrast Styles
    @media screen and (-ms-high-contrast: active) {
      .ng-arrow {
        border-color: $green transparent transparent;
      }
    }
    @media screen and (-ms-high-contrast: black-on-white) {
      .ng-arrow {
        border-color: black transparent transparent;
      }
    }
    @media screen and (-ms-high-contrast: white-on-black) {
      .ng-arrow {
        border-color: white transparent transparent;
      }
    }
  }
}

.offer-redemption-modal,
.error-override-modal {
  .modal-content {
    padding: 0 !important;
  }
}

.round-up-modal {
  .modal-dialog {
    max-width: 762px;
  }
  .modal-content {
    min-height: 433px;
    border-radius: 0;
    padding: 10px;
  }
}

.taxes-and-fees-modal {
  .modal-dialog {
    max-width: 375px;
  }
  .modal-content {
    padding: 18px 20px;
  }
}

.modal-content {
  padding-top: 0;

  .card {
    box-shadow: none;
  }

  body.modal-open {
    overflow: hidden;
    position: fixed;
  }

  .product-variant-card {
    background-color: $white !important;
  }

  .modal-dialog {
    position: fixed !important;
  }

  .alert-modal &,
  .tip-modal &,
  .location-selection-modal & {
    padding-top: 0;
    padding-bottom: 0;
  }

  .custom-control-input:not(:checked) ~ .custom-control-label::before {
    background-color: $white !important;
  }

  .btn-secondary:not(.dropdown-toggle) {
    &:focus {
      &:after {
        border: none;
      }
    }
  }
}

body.modal-open {
  width: 100%;
  position: absolute;

  .modal {
    padding: 0 1rem;

    @include media-breakpoint-up(md) {
      background: rgba(0, 0, 0, 0.6);
    }

    &.show {
      .modal-dialog {
        top: 50%;
        transform: translate(0, -50%);

        @media only screen and (max-height: 967px) {
          top: 0;
          transform: translate(0, 0);
        }
      }
    }
  }
}

body.mobile-menu-open {
  overflow: hidden;
}

.modal-dialog {
  margin: auto;

  @include media-breakpoint-up(md) {
    max-width: 700px;
  }
}

//.modal-dialog {
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  overflow-y: auto;
//  min-height: calc(100vh - 60px);
//}
//@media (max-width: 767px) {
//  .modal-dialog {
//    min-height: calc(100vh - 20px);
//  }
//}

.modal-lg {
  max-width: 90% !important;

  @include media-breakpoint-down(sm) {
    margin: 0;
    max-width: 100% !important;
  }
}

.product-option-modal {
  &.modal {
    @include media-breakpoint-down(sm) {
      padding: 0 !important;
      //overflow-y: hidden;
    }
  }

  .modal-dialog {
    max-width: 1190px !important;
  }
}

.locale-modal {
  .modal-content {
    position: absolute;
    top: calc(#{$nav-height-mobile});

    @include media-breakpoint-up(md) {
      position: relative;
      top: unset;
    }
  }
  .modal-dialog {
    max-width: 975px !important;
  }
  .locale-flag {
    font-size: 2.5em;
  }
  .locale-text {
    font-size: 12px;

    &.selected {
      font-size: 14px;
    }
  }
  .modal-content__anchor {
    &:hover {
      .locale-text {
        text-decoration: underline;
      }
    }
  }
}

// .universal-modal-class {
//   overflow: hidden;
//   overflow-y: hidden!important;
//   position:fixed;
// }

svg[type='edit'],
svg.action {
  width: 18px;
  height: 18px;
  opacity: 0.6;
  transition: 200ms all;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  @include media-breakpoint-up(md) {
    width: 28px;
    height: 28px;
  }
}

.specialRequestModal {
  padding-bottom: 20px;

  @include media-breakpoint-down(sm) {
    height: 97vh;
  }

  svg {
    font-size: 24px;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  .img-fluid {
    height: 60px;

    @include media-breakpoint-down(sm) {
      height: 30px;
    }
  }

  .close-btn {
    position: absolute;
    top: 14px;
    right: 14px;
    border: none;
    cursor: pointer;
    background: transparent;

    &:focus {
      outline: none;
    }
  }

  p {
    font-size: ws-get-font-size('small');

    @include media-breakpoint-down(sm) {
      text-align: left;
      margin-bottom: 5px;
      font-size: ws-get-font-size('medium');
    }
  }

  .container {
    @include media-breakpoint-down(sm) {
      padding: 0 5px;
    }
  }

  .modal-body {
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding: 20px 30px 0;
    }
  }

  .modal-header {
    border: none;
    background: $white;
    align-items: center;

    @include media-breakpoint-down(sm) {
      border-radius: 0;
      padding-left: 30px;
      border-bottom: 1px solid $black-5;
    }

    .modal-title {
      margin-top: 0.25rem;

      @include media-breakpoint-up(md) {
        margin: 0 auto;
      }
    }
  }

  .modal-footer {
    padding: 0;
    border: none;
    margin-top: 30px;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 20px 20px 0;
      display: block;
      position: relative;
    }

    .btn {
      width: 50%;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}

.location-image {
  width: 70%;
  right: -60px;
  position: absolute;

  img {
    object-fit: cover;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.border-bottom-shade {
  border-bottom: $divider-border;
}

.border-top-shade {
  border-top: $divider-border;
}

// Carousel
.carousel-indicators {
  li {
    width: 8px;
    height: 8px;
    background: none;
    border-radius: 50%;
    border: 1px solid ws-get-color('black', 50);

    &.active {
      background-color: ws-get-color('black');
      border-color: ws-get-color('black');
    }
  }
}

// Popover
.popover {
  &.alert-danger,
  .popover-header {
    background-color: #f9d8d6;
    border-color: #f7c8c5;

    .arrow::after {
      border-top-color: #f9d8d6;
    }

    .popover-body {
      color: #761f19;
    }
  }
}

.sticky-bottom {
  bottom: 0;
  position: sticky;
  min-height: 100px;
  z-index: $zindex-sticky;

  .icon-large {
    width: 4rem;
    height: 4rem;
  }

  .info-icon {
    fill: $green;
  }

  .delivery-flag,
  .close {
    margin-left: auto;
  }

  .close-icon {
    width: 2rem;
    height: 2rem;
  }

  .small-text {
    line-height: 1;
    font-size: 1rem;
  }

  .medium-text {
    line-height: 1;
    font-size: 1.3rem;
  }

  .order-btn {
    padding: 15px 10px;
  }
}

.map-info-window {
  h5 {
    margin: 1px; // needed to prevent scroll bar on Win10/chrome
  }
}

google-map {
  .gm-style-iw > button {
    display: none !important;
  }

  .map-info-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    outline: none;
    background-color: transparent;
    color: inherit;
    font-family: Arial, Baskerville, monospace;
    font-size: 12px;
    cursor: pointer;
    opacity: 0.75;
    -webkit-appearance: none;
    appearance: none;
    padding: 0.25rem;
  }
}

.menu-messages {
  background-color: white;
  padding: 24px 27px;

  .message {
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        width: 24px;
        height: 24px;
        margin-right: 26px;
      }
    }
  }

  .message + .message {
    margin-top: 15px;
  }
}

.small-panel-title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  svg {
    width: 20px;
    margin-right: 10px;
  }

  .h6 {
    flex: 1;
    margin: 0;
    line-height: 14px;
    text-transform: uppercase;
    font-size: $font-size-lg;
  }
}

.location-search {
  .searching-icon-container {
    .lds-dual-ring {
      &:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        margin: 1px;
        border-radius: 50%;
        border: 0;
        animation: 0;
        background: transparent url('/assets/images/wingstop-loader.svg')
          no-repeat center center;
        background-size: cover;
      }
    }
  }
}

.location-search .dropdown-menu {
  //Reduce padding between search box and the results
  padding-top: 0;
  margin-top: -1px;

  .dropdown-item {
    //Drop shadow around search results drop down - match Google Places
    font-size: 16px;
    height: 44px;
    display: flex;
    align-items: center;
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    padding-left: 20px;

    //Hover state - use hex F0F0E8 instead of dark green
    &.active {
      background-color: $kraft-50;
      color: unset;
    }
  }
}

// Recaptcha Styling
.recaptcha-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.pointer {
  cursor: pointer;
}

@include media-breakpoint-up(md) {
  .location-search .dropdown-menu {
    width: 550px;
  }
}

@include media-breakpoint-only(xs) {
  .location-search .dropdown-menu {
    width: 100%;
  }
}

.dropdown-item {
  overflow: hidden;
}

input[type='text'],
input[type='number'],
textarea {
  font-size: 16px !important;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

// Navigation arrows

.slick-arrow::before {
  display: none !important;
}

.slick-prev.slick-arrow {
  z-index: 2;
  left: -10px;
  background: white 50% 50% no-repeat
    url('/assets/icons/green-arrow-left-small.svg');
  height: 50px;
  width: 50px;
  -webkit-box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  border: 1px solid white;

  &:hover {
    background: $green 50% 50% no-repeat
      url('/assets/icons/white-arrow-left-small.svg');
    border: 1px solid $green;
  }

  &:focus {
    background: $green 50% 50% no-repeat
      url('/assets/icons/white-arrow-left-small.svg');
    border: 1px solid $green;
  }
}

.slick-next.slick-arrow {
  z-index: 2;
  right: -10px;
  background: white 50% 50% no-repeat
    url('/assets/icons/green-arrow-right-small.svg');
  height: 50px;
  width: 50px;
  -webkit-box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  border: 1px solid white;

  &:hover {
    background: $green 50% 50% no-repeat
      url('/assets/icons/white-arrow-right-small.svg');
    border: 1px solid $green;
  }

  &:focus {
    background: $green 50% 50% no-repeat
      url('/assets/icons/white-arrow-right-small.svg');
    border: 1px solid $green;
  }
}

// Slick Dots overwrite
.digital-menu-specials-container {
  .slick-dotted.slick-slider {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .slick-list {
    height: 7.5rem;
  }
  .slick-dots {
    position: relative;
    text-align: right;
    bottom: 3px;
    width: 96%;
    li {
      width: 2px;
      margin: 0;
      margin-right: 1rem;
    }
    li button {
      border: 1px solid #7f7f7f;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      padding: 3px;
    }
    li button::before {
      content: none;
    }
    li.slick-active button {
      background-color: $black;
    }
  }
}

//.slick-disabled {
//  border-color: $black-50 !important;
//
//  &.slick-prev {
//    background: $black-50 50% 50% no-repeat url('/assets/icons/white-arrow-left-small.svg') !important;
//  }
//  &.slick-next {
//    background: $black-50 50% 50% no-repeat url('/assets/icons/white-arrow-right-small.svg') !important;
//  }
//
//  &:focus {
//    border-color: $black-50 !important;
//  }
//}

// IE11 high Contrast Left Arrow Styles
@media screen and (-ms-high-contrast: active) {
  .slick-prev.slick-arrow {
    background: $green 50% 50% no-repeat
      url('/assets/icons/white-arrow-left-small.svg');
  }
}

@media screen and (-ms-high-contrast: black-on-white) {
  .slick-prev.slick-arrow {
    background: black 50% 50% no-repeat
      url('/assets/icons/green-arrow-left-small.svg');
  }
}

@media screen and (-ms-high-contrast: white-on-black) {
  .slick-prev.slick-arrow {
    background: white 50% 50% no-repeat
      url('/assets/icons/green-arrow-left-small.svg');
  }
}

// IE11 high Contrast Right Arrow Styles
@media screen and (-ms-high-contrast: active) {
  .slick-next.slick-arrow {
    background: $green 50% 50% no-repeat
      url('/assets/icons/white-arrow-right-small.svg');
  }
}

@media screen and (-ms-high-contrast: black-on-white) {
  .slick-next.slick-arrow {
    background: black 50% 50% no-repeat
      url('/assets/icons/green-arrow-right-small.svg');
  }
}

@media screen and (-ms-high-contrast: white-on-black) {
  .slick-next.slick-arrow {
    background: white 50% 50% no-repeat
      url('/assets/icons/green-arrow-right-small.svg');
  }
}

@include media-breakpoint-down(md) {
  .location-pages-specials-carousel > .slick-prev.slick-arrow {
    left: -30px;
    height: 30px;
    width: 30px;
  }

  .location-pages-specials-carousel > .slick-next.slick-arrow {
    right: -30px;
    height: 30px;
    width: 30px;
  }
}

// Addons slider
.addon-slider-container {
  .slick-list {
    min-height: 315px;

    @include media-breakpoint-down(sm) {
      min-height: 258px;
    }
  }
  .slick-prev.slick-arrow {
    left: 25px;
  }
  .slick-next.slick-arrow {
    right: 25px;
  }
}
// Upsell slider
.upsell-slider-container {
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: center;
    .slick-track {
      width: auto !important;
    }
    .slide {
      width: 18rem !important;
    }
  }

  .slick-list {
    min-height: 315px;

    @include media-breakpoint-down(sm) {
      min-height: 258px;
    }
  }
  .slick-prev.slick-arrow {
    left: 25px;
  }
  .slick-next.slick-arrow {
    right: 25px;
  }
}

.micro {
  font-size: $font-size-xsm;
}

// Hides floating language button injected sometimes by mp_easylink
#elDivMobile {
  display: none !important;
}

// Abbr styling for required form controls
abbr.req {
  text-decoration: none;
}

// Google Maps IE11 High Contrast Fix
@media screen and (-ms-high-contrast: active) {
  .gm-style {
    div {
      background: transparent !important;
    }
  }
}

// Checkout - Credit Card Number + CVV iframe fix
#iframe-cardNumber,
#iframe-cvv {
  @include media-breakpoint-down(sm) {
    height: unset !important;
  }
}

.btn-outline {
  border-color: $green;
  color: $green;
  background-color: white;
}

.green {
  color: $green !important;
}

body.bottom-sheet-open {
  overflow: hidden;
}

.hideCaptcha {
  display: none;
}
