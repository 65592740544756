@use 'sass:math';

// Primary colors
$green: #006938;
$gold: #a19958;
$kraft: #e1e1d2;
$red: #e23b30;
$orange: #ff9a00;
$black: #000000;
$white: #ffffff;

// Alternative colors
$green-80: #338760;
$green-50: #7fb49b;
$gold-dark: #827c48;
$gold-80: #b4ad79;
$gold-50: #d0ccab;
$gold-30: #a5a287;
$gold-light: #e6e6d3;
$kraft-80: #e7e7db;
$kraft-50: #f0f0e8;
$black-80: #333333;
$black-50: #7f7f7f;
$black-5: #e6e6e6;
$red-80: #e86259;
$red-50: #f09d97;
$orange-80: #ffae33;
$orange-50: #ffcc7f;
$gray-1: #5b5b5b;
$gray-2: #f8f8f8;
$gray-3: #979797;
$gray-4: #666666;
$gray-5: #4a4a4a;

// Bootstrap theme colors
$theme-colors: (
  'primary': $green,
  'secondary': $black,
  'green': $green,
  'gold': $gold,
  'kraft': $kraft,
  'red': $red,
  'orange': $orange,
  'green-80': $green-80,
  'green-50': $green-50,
  'gold-dark': $gold-dark,
  'gold-80': $gold-80,
  'gold-50': $gold-50,
  'kraft-80': $kraft-80,
  'kraft-50': $kraft-50,
  'black': $black,
  'black-80': $black-80,
  'black-50': $black-50,
  'black-5': $black-5,
  'gray': $black-50,
  'gray-80': $black-80,
  'white': $white,
  'red-80': $red-80,
  'red-50': $red-50,
  'orange-80': $orange-80,
  'orange-50': $orange-50,
);

$body-color: $black;
$navbar-light-color: $black;
$text-muted: $black-50;
$form-input-bg-colors: ('green', 'gold', 'red', 'white', 'gray');
$hr-border-color: $gray-3;
$border-color: rgba(0, 0, 0, 0.2);

// Fonts
$font-family-base: 'Open Sans', sans-serif;
$headings-font-family: 'FuturaStd', sans-serif;
$headings-font-weight: 900;
$font-size-xsm: math.div(12, 16) * 1rem;
$font-size-sm: math.div(14, 16) * 1rem;
$font-size-base: math.div(16, 16) * 1rem;
$font-size-lg: math.div(18, 16) * 1rem;
$font-sizes: (
  'xsmall': $font-size-xsm,
  'small': $font-size-sm,
  'medium': $font-size-base,
  'default': $font-size-base,
  'normal': $font-size-base,
  'large': $font-size-lg,
);
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 900;
$font-weights: (
  'light': $font-weight-light,
  'normal': $font-weight-normal,
  'semi-bold': $font-weight-semi-bold,
  'bold': $font-weight-bold,
  'extra-bold': $font-weight-bolder,
);

// Typography
$h1-font-size: math.div(48, 16) * 1rem;
$h2-font-size: math.div(36, 16) * 1rem;
$h3-font-size: math.div(30, 16) * 1rem;
$h4-font-size: math.div(24, 16) * 1rem;
$h5-font-size: math.div(22, 16) * 1rem;
$h6-font-size: math.div(18, 16) * 1rem;
$lead-font-size: $font-size-lg;
$lead-font-weight: 400;
$small-font-size: $font-size-sm;
$badge-font-size: $font-size-sm;
$badge-font-weight: $font-weight-semi-bold;

// Buttons + Forms
$input-color: $black-50;
$input-border-color: $black-50;
$input-placeholder-color: $black-50;
$input-plaintext-color: $black-50;
$input-box-shadow: 0;
$input-focus-box-shadow: 0 0 0 1px $green;
$input-focus-border-color: $green;
$btn-font-size: $font-size-lg;
$btn-font-weight: 900;
$btn-disabled-opacity: 1;
$input-btn-padding-y: 0.65rem;
$input-btn-padding-x: 1rem;
$custom-control-indicator-bg: $white;
$custom-control-indicator-box-shadow: none;
$custom-control-indicator-disabled-bg: $black-5;
$custom-control-label-disabled-color: $black-50;
$custom-control-indicator-active-bg: $green-80;

// Range
$custom-range-track-height: math.div(4, 16) * 1rem;
$custom-range-track-bg: transparent;
$custom-range-track-border-radius: 0.5rem;
$custom-range-track-box-shadow: none;
$custom-range-thumb-width: math.div(42, 16) * 1rem;
$custom-range-thumb-border: 2px solid $white;
$custom-range-thumb-border-radius: 3rem;
$custom-range-thumb-box-shadow: 0 2px 1px 0 rgba($black, 0.15);
$custom-range-thumb-active-bg: $green-80;
$custom-range-thumb-disabled-bg: $black-5;

// Tabs
$nav-tabs-border-color: $black-50;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: $black;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-bg: $black;
$nav-tabs-link-active-border-color: $black;

// Misc
$box-shadow: 0 0 2px rgba($black, 0.2);
$box-shadow-banner: 0 6px 4px -4px rgba($black, 0.5);
$spacing: (
  'none': 0,
  'xsmall': 1,
  'small': 2,
  'medium': 3,
  'default': 3,
  'large': 4,
  'xlarge': 5,
);
$widths: (
  'small': 'sm',
  'medium': 'md',
  'large': 'lg',
  'xlarge': 'xl',
);

$badge-padding-y: 0;
$badge-pill-padding-x: 1em;

$nav-height-desktop: 100px;
$nav-height-mobile: 62px;
$max-width-desktop: 1440px;
$top-layer: 100;
$divider-border: 1px solid rgba(0, 0, 0, 0.15);
$app-banner-height-mobile: 100px;
