@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

@font-face {
  font-family: 'FuturaStd';
  src: url('../assets/fonts/36BBD1_0_0.eot');
  src: url('../assets/fonts/36BBD1_0_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/36BBD1_0_0.woff2') format('woff2'),
    url('../assets/fonts/36BBD1_0_0.woff') format('woff'),
    url('../assets/fonts/36BBD1_0_0.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'FuturaStd';
  src: url('../assets/fonts/36BBD1_1_0.eot');
  src: url('../assets/fonts/36BBD1_1_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/36BBD1_1_0.woff2') format('woff2'),
    url('../assets/fonts/36BBD1_1_0.woff') format('woff'),
    url('../assets/fonts/36BBD1_1_0.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'FuturaStd';
  src: url('../assets/fonts/36BBD1_2_0.eot');
  src: url('../assets/fonts/36BBD1_2_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/36BBD1_2_0.woff2') format('woff2'),
    url('../assets/fonts/36BBD1_2_0.woff') format('woff'),
    url('../assets/fonts/36BBD1_2_0.ttf') format('truetype');
  font-weight: 900;
  font-style: oblique;
}

@font-face {
  font-family: 'FuturaStd Condensed';
  src: url('../assets/fonts/38E17C_0_0.eot');
  src: url('../assets/fonts/38E17C_0_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/38E17C_0_0.woff2') format('woff2'),
    url('../assets/fonts/38E17C_0_0.woff') format('woff'),
    url('../assets/fonts/38E17C_0_0.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Helvetica Ultra Compressed';
  src: url('../assets/fonts/38F567_0_0.eot');
  src: url('../assets/fonts/38F567_0_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/38F567_0_0.woff2') format('woff2'),
    url('../assets/fonts/38F567_0_0.woff') format('woff'),
    url('../assets/fonts/38F567_0_0.ttf') format('truetype');
  font-weight: 400;
}
